<!-- 授权结果页面 -->
<template>
  <div class="result_warpper">
    <div class="result_title_bar" :style="{ background: result.color }">
      {{ result.title }}
    </div>
    <div class="result_content">
      <img class="result_img" :src="result.img" alt="" />
      <div class="result_text" :style="{ color: result.color }">{{ result.text }}</div>
      <div class="result_sub_text">{{ result.subText }}</div>
    </div>
  </div>
</template>

<script>
const failIcon = require('@/assets/images/login/auth_fail_icon.png')
const successIcon = require('@/assets/images/login/auth_success_icon.png')
export default {
  name: '',
  components: {},
  data() {
    return {
      result: {
        title: '感谢您的授权!',
        img: failIcon,
        text: '授权成功',
        subText: '已经授权成功，你可以关闭此页面',
        color: '#ff7431'
      }
    }
  },
  methods: {
    getAuthResult(status) {
      this.result = {
        title: status ? '感谢您的授权!' : '很抱歉,没有授权成功,如有疑问请联系相关客服。',
        img: status ? successIcon : failIcon,
        text: status ? '授权成功' : '授权失败',
        subText: status ? '已经授权成功，你可以关闭此页面' : '授权失败，请关闭此页面重新授权',
        color: status ? '#ff7431' : '#f61b29'
      }
    }
  },
  mounted() {
    let status = this.$route.query.status == 1 ? true : false
    this.getAuthResult(status)
    console.log(this.$route)
  }
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
.result_warpper {
  .result_title_bar {
    padding: 15px 20px;
    font-weight: bold;
    color: #fff;
    background: #ff7431;
  }
  .result_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    .result_img {
    }
    .result_text {
      margin-top: 20px;
      font-size: 18px;
      font-weight: bold;
      color: #ff7431;
    }
    .result_sub_text {
      margin-top: 40px;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>
